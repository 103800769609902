/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import azorshare from '../../assets/recentprojects/azorshare.png';
import BouncyB from '../../assets/recentprojects/BouncyB.png';
import DaillyIntelect from '../../assets/recentprojects/DaillyIntelect.png';
import autocanto from '../../assets/recentprojects/autocanto.png';
import sonicArts from '../../assets/recentprojects/SonicArts.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    { 
      id: 1,
      title: 'Sonic-Arts Logo Generator', 
      description: `Developed a logo generator using python: Generate custom idle and Animated logos in seconds, implemented into a website using Django framework.` ,
      skills: `Python | HTML/CSS/JS | Django API`,
      image: `${sonicArts}`,
    },
    { 
      id: 2,
      title: 'Dailly-Intelect', 
      description: `I am designing and developed a mobile app using react-native: Discover learning recommendations that adjust to your objectives and areas of interest, directing you on what and how to learn. You can keep a close eye on your skill development and learning progress, the app keeps track of all of your daily academic accomplishments.`,
      skills: ` Python, React-Native, Django API`,
      image: `${DaillyIntelect}`,
    },
    { 
      id: 3,
      title: 'AzorShare', 
      description: `I am designing and developed a mobile app using kotlin: A social media platform for the Azores, Portugal. Users can share their experiences, photos, and videos of the Azores.`,
      skills: ` Python, Kotlin, Django API, java`,
      image: `${azorshare}`,
    },
    { 
      id: 4,
      title: 'Bouncy Balls', 
      description: `I am developing a mobile game using DOGOT engine: A simple game where a ball gets bigger everytime it colides with another ball.`,
      skills: ` GODOT Engine | GDScript | C++ `,
      image: `${BouncyB}`,
    }
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        {projects.map((project) => (
          <div className="project" key={ project.id }>
            <div className="__img_wrapper">
              <img src={ project.image } alt={ project.alter }/>
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                <TextDecrypt text={ project.id + '. ' + project.title } />
              </h3>
              <p className="description">
                { project.description }
              </p>
              <p className="skills">
                { project.skills }
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
